<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title is-size-3">Statistics</p>
      <button class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content container">
      <div class="tabs is-centered is-fullwidth is-large">
        <ul>
          <li
            v-for="(cryptoTypeTab, index) in cryptoTypeTabs"
            @click="activeTab(index)"
            :key="index"
            :class="cryptoTypeTab.active ? 'is-active' : ''"
          >
            <a class="is-size-5">{{ cryptoTypeTab.title }}</a>
          </li>
        </ul>
      </div>
      <CryptoTypeStatistics
        v-if="isLoaded"
        :currentTab="currentTab"
        :cryptoStatisticsData="stats.cryptoStatisticsData"
        :cryptoTypeStats="stats.cryptoTypeStats"
        :logs="monthlyLogs"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { cryptoList } from '@/utils/utils'
import AdminService from '@/services/Admin'

import CryptoTypeStatistics from '@/components/AdminComponents/Statistics/CryptoTypeStatistics.vue'

export default defineComponent({
  components: {
    CryptoTypeStatistics,
  },
  setup() {
    const cryptoTypeTabs = ref(
      cryptoList.map((c) => ({
        title: c.type,
        active: false,
      }))
    )
    const currentTab = computed(
      () => cryptoTypeTabs.value.filter((tab) => tab.active)[0]
    )
    cryptoTypeTabs.value[0].active = true
    const activeTab = (tabIndex: number) => {
      cryptoTypeTabs.value = cryptoTypeTabs.value.map((tab, index) => {
        const active = tabIndex === index
        return { ...tab, active }
      })
    }
    const monthlyLogs = ref()
    const stats = ref()
    const isLoaded = ref(false)
    const loadData = async () => {
      const logs = await AdminService.getMonthlyLog()
      const { cryptoStatisticsData, cryptoTypeStats } =
        await AdminService.loadGeneralData()
      stats.value = { cryptoStatisticsData, cryptoTypeStats }
      monthlyLogs.value = logs
      isLoaded.value = true
    }
    loadData()
    return {
      cryptoTypeTabs,
      currentTab,
      activeTab,
      isLoaded,
      monthlyLogs,
      stats,
    }
  },
})
</script>
