
import { computed, defineComponent, ref } from 'vue'
import { cryptoList } from '@/utils/utils'
import AdminService from '@/services/Admin'

import CryptoTypeStatistics from '@/components/AdminComponents/Statistics/CryptoTypeStatistics.vue'

export default defineComponent({
  components: {
    CryptoTypeStatistics,
  },
  setup() {
    const cryptoTypeTabs = ref(
      cryptoList.map((c) => ({
        title: c.type,
        active: false,
      }))
    )
    const currentTab = computed(
      () => cryptoTypeTabs.value.filter((tab) => tab.active)[0]
    )
    cryptoTypeTabs.value[0].active = true
    const activeTab = (tabIndex: number) => {
      cryptoTypeTabs.value = cryptoTypeTabs.value.map((tab, index) => {
        const active = tabIndex === index
        return { ...tab, active }
      })
    }
    const monthlyLogs = ref()
    const stats = ref()
    const isLoaded = ref(false)
    const loadData = async () => {
      const logs = await AdminService.getMonthlyLog()
      const { cryptoStatisticsData, cryptoTypeStats } =
        await AdminService.loadGeneralData()
      stats.value = { cryptoStatisticsData, cryptoTypeStats }
      monthlyLogs.value = logs
      isLoaded.value = true
    }
    loadData()
    return {
      cryptoTypeTabs,
      currentTab,
      activeTab,
      isLoaded,
      monthlyLogs,
      stats,
    }
  },
})
