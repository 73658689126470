<template>
  <div class="block">
    <div class="columns has-text-centered">
      <div class="column">
        <div class="box">
          <p class="is-size-4">Encryption</p>
          <p class="is-size-3">
            {{ generalStats.encryption }} (+{{ generalStats.newEncryption }})
          </p>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <p class="is-size-4">Decryption</p>
          <p class="is-size-3">
            {{ generalStats.decryption }} (+{{ generalStats.newDecryption }})
          </p>
        </div>
      </div>
    </div>
    <template v-if="isLoaded">
      <MonthlyStatisticsChart
        :title="`${title} Monthly Logs`"
        :monthlyLogs="cryptoLogs"
      />
      <CryptoTypeStatisticsChart
        :title="`${title} Algorithms`"
        :cryptoTypeStats="cryptoNameStats"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, toRefs } from 'vue'
import AdminService from '@/services/Admin'

import MonthlyStatisticsChart from '../Chart/MonthlyStatisticsChart.vue'
import CryptoTypeStatisticsChart from '../Chart/CryptoTypeStatisticsChart.vue'

export default defineComponent({
  props: {
    currentTab: { type: Object, required: true },
    logs: Object,
    cryptoStatisticsData: {
      type: Object,
      required: true,
    },
    cryptoTypeStats: Array,
  },
  components: { MonthlyStatisticsChart, CryptoTypeStatisticsChart },
  setup(props) {
    const { currentTab, logs, cryptoTypeStats, cryptoStatisticsData } =
      toRefs(props)
    watch(currentTab, () => loadData())
    const cryptoType = computed(() => currentTab.value.title)
    const cryptoLogs = ref()
    const cryptoNameStats = ref()
    const generalStats = ref({
      encryption: 0,
      decryption: 0,
      newEncryption: 0,
      newDecryption: 0,
    })
    const analyzeStats = () => {
      const stats = {
        encryption: 0,
        decryption: 0,
        newEncryption: 0,
        newDecryption: 0,
      }
      cryptoLogs.value.forEach((log: any) => {
        if (log.type === 'encrypt') {
          stats.newEncryption += 1
        } else {
          stats.newDecryption += 1
        }
      })
      const { encrypt, decrypt } = cryptoTypeStats.value?.filter(
        (crypto: any) => crypto.type === cryptoType.value
      )[0] as any
      stats.encryption = encrypt
      stats.decryption = decrypt
      generalStats.value = stats
      cryptoNameStats.value = Object.keys(
        cryptoStatisticsData.value[cryptoType.value]
      ).map((cryptoName) => {
        const { encrypt, decrypt } =
          cryptoStatisticsData.value[cryptoType.value][cryptoName]
        return {
          type: cryptoName,
          encrypt,
          decrypt,
        }
      })
    }
    const isLoaded = ref(false)
    const loadData = async () => {
      isLoaded.value = false
      const monthlyLogsByCryptoType =
        await AdminService.getMonthlyLogByCryptoType(
          logs.value,
          cryptoType.value
        )
      cryptoLogs.value = monthlyLogsByCryptoType
      isLoaded.value = true
      analyzeStats()
    }
    loadData()
    return {
      cryptoLogs,
      title: cryptoType,
      generalStats,
      cryptoNameStats,
      isLoaded,
    }
  },
})
</script>
